document.body.addEventListener('arc_purchase', function(e){
	const { purchase } = e.detail;

	window.AddShoppersWidgetOptions = { 'loadCss': false, 'pushResponse': false };

	const offerCode = '';
	purchase.orders.forEach(order => {
		order?.tags?.forEach(x => {
			if (x.valueCode) {
				offerCode = x.valueCode;
			}
		})
	});

	window.AddShoppersConversion = {
		order_id: purchase.code,
		value: purchase.total,
		email: purchase?.user?.email,
		currency: 'USD',
		offer_code: offerCode,
	};

	(!function(){
		var t=document.createElement("script");
		t.async=!0,
		t.id="AddShoppers",
		t.src="https://shop.pe/widget/widget_async.js#6528fbaee6addf73c13f7c38",
		document.getElementsByTagName("head")[0].appendChild(t)
	}());
});

